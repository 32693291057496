<template>
  <div class="w-block__pre-heading">
    <!-- pre-heading block -->
    <v-container
      v-if="$route.name === 'index' && $voicer.isOpenSite() === false"
      :class="{
        'pa-2': $voicer.isDesktopOrTablet,
      }"
    >
      <v-layout row wrap>
        <v-flex xs12 sm6>
          <div class="text-sm-left">
            Régulièrement, retrouvez les contenus inspirants que nous
            sélectionnons pour vous !
          </div>
          <div>
            En ce moment :
            <strong>
              <span style="color: #ea644e"
                >« Construire ensemble »</span
              >
            </strong>
          </div>
        </v-flex>
        <v-flex xs12 sm6 class="text-xs-right">
          <v-layout class="mr-2 row-reverse">
            <img
              :src="require('~/assets/images/logo_uhe.svg')"
              height="49"
              alt="CECA"
              class="mr-4"
            />
            <img
              :src="require('~/assets/images/logo_mm.svg')"
              height="47"
              alt="Mediameeting"
              class="mr-4"
            />
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import WFeedbackPopup from '../components/WFeedbackPopup'

export default {
  components: {
    WFeedbackPopup,
  },
  data() {
    return {
      showFeedbackModal: false,
    }
  },
  computed: {
    ...mapGetters({
      userName: 'auth/name',
    }),
  },
}
</script>

<style lang="scss" scoped>
.message_welcome_sir {
  border-radius: 6px !important;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 600px) {
  .message_welcome_sir {
    flex-direction: column;
    align-items: center;
    div {
      margin-top: 15px;
    }
  }
}

.w-block__pre-heading {
  font-size: smaller;

  .row-reverse {
    flex-direction: row-reverse;
  }
}

.w-block__pre-heading {
  font-size: smaller;

  .row-reverse {
    flex-direction: row-reverse;
  }
}
</style>
