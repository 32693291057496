/**
 * configuration applied when BROCOLI_API_MODE = staging
 */
module.exports = {
  useShell: [
    'bb-default',
    {
      mode: 'universes',
      universes: [
        {
          type: 'tag',
          tagId: '628ce95f039b5c0c55437ffb',
          i18n: {
            fr: {
              title: 'Sélection du mois',
              description: `Les Jeux Olympiques et Paralympiques de Paris 2024 J-30 : ces sportifs qui nous inspirent...`,
            },
          },
          heading: {
            color: '#ea644e',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
            backgroundImage(context) {
              return context.$voicer.isMobile
                ? require('~/assets/images/selectionmois-mobile.png')
                : require('~/assets/images/selectionmois.png')
            },
          },
        },
        {
          type: 'tag',
          tagId: '628cf0ef039b5c7d51438004',
          i18n: {
            fr: {
              title: 'Tous les contenus',
              description: `Tous les épisodes de Second Souffle`,
            },
          },
          heading: {
            color: '#ea644e',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
            backgroundImage(context) {
              return context.$voicer.isMobile
                ? require('~/assets/images/touslescontenus-mobile.png')
                : require('~/assets/images/touslescontenus.png')
            },
          },
        },
      ],
    },
  ],
}
