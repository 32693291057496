const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    config = loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    /**
     * common configuration here
     */
    siteId: '5e9f2b38c3603d3a9c270884',
    enableExperimentalBlocks: true,
    enableSplashScreen: 'wavy',
    showCardDescription: true,
    authMode: [
      'local'
    ],
    authLocalOptions: {
      mode: 'otp',
    },
    metadatas: {
      name: 'Second Souffle',
      colorPrimary: '#ea644e',
      colorAccent: '#69605d',
    },
    analytics: {
      matomo: '20',
    },
    useLTS: true,
    useShell: [
      'bb-default',
      {
        mode: 'universes',
        universes: [
          {
            type: 'tag',
            tagId: '62a1e3adb83b8eef2bfafdb2',
            i18n: {
              fr: {
                title: 'Sélection du mois',
                description: `Construire ensemble`,
              },
            },
            heading: {
              color: '#ea644e',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              backgroundImage(context) {
                return context.$voicer.isMobile
                  ? require('~/assets/images/selectionmois-mobile.png')
                  : require('~/assets/images/selectionmois.png')
              },
            },
          },
          {
            type: 'tag',
            tagId: '62a1e3b5b83b8e35d4fafdb3',
            i18n: {
              fr: {
                title: 'Tous les contenus',
                description: `Tous les épisodes de Second Souffle`,
              },
            },
            heading: {
              color: '#ea644e',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              backgroundImage(context) {
                return context.$voicer.isMobile
                  ? require('~/assets/images/touslescontenus-mobile.png')
                  : require('~/assets/images/touslescontenus.png')
              },
            },
          },
        ],
        view: {
          allowHeadingOnMobile: false,
          featuredContents: false,
        },
      },
    ],
  },
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
